.sort-selector.ant-select-dropdown,
.ant-segmented-item-selected {
  .anticon {
    color: var(--colorButtonPrimaryBg, $defaultColorButtonPrimaryBg);
  }
}

.sort-selector .ant-select-selector {
  font-size: 1.4rem !important;
}

.product-panel {
  width: 100%;
  padding: 15px 0 8px 0;
  margin: 15px 0 25px 0;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 5px 13px #00000030;
  -webkit-box-shadow: 0 5px 13px #00000030;
}

.product__overview--condensed {
  overflow: hidden; // Keep table within border radius

  th,
  td {
    padding-left: 15px !important;
  }
}

.quickview__extra__item__value,
.ant-table-cell {
  :is(div, a, li):not(:last-of-type, .subject-value) {
    &::after {
      content: ", ";
      color: var(--colorTextBase, $defaultColorTextBase) !important;
      margin-right: 0.3rem;
    }
  }
}
