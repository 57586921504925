.structure {
  .nested {
    .card {
      -webkit-box-shadow: none;
      box-shadow: none;

      .card-header {
        padding: 6px 8px 6px 12px;
      }
    }

    &.level-1 {
      > .card {
        > .card-header {
          color: white !important;
          background-color: var(--colorEnvBaseBg, $primary-color);
          border: none;

          .icon {
            color: white !important;
          }
        }
      }
    }
  }

  .card {
    overflow: auto;
    border-radius: 7px;

    &:hover {
      .icon:hover {
        color: var(
          --colorButtonPrimaryActiveBg,
          $defaultColorButtonPrimaryActiveBg
        );
        -ms-transform: scale(1.25);
        transform: scale(1.25);
      }
    }
  }
}
