.query-tags {
  flex-flow: wrap;

  .tag {
    display: inline-block;
    font-size: $fontSizeSM;
    font-weight: $fontWeightNormal;
    color: #2b2b2b;
    background-color: #f7f9fa !important;
    height: auto;
    padding: 10px 15px;
    border: 1px solid #c3c7cb;
    border-radius: 5px !important;
    margin-right: 1em;
    margin-bottom: 1em;
    user-select: none;

    .tag-label {
      padding-right: 0.2em;
    }

    .tag-close-btn {
      font-size: $fontSizeSM;
      margin-left: 12px;
    }

    &:hover {
      cursor: pointer;
      background-color: #e6e8e9 !important;
      transform: scale(1.1);

      .tag-text {
        text-decoration-line: line-through;
      }

      .tag-close-btn {
        color: red;
        font-weight: 700;
      }
    }

    &.tag-small {
      padding: 6px 6px;
      margin-right: 6px;
      margin-bottom: 6px;

      .tag-close-btn {
        margin-left: 0px;
      }
    }
  }

  @include media-breakpoint-down(md) {
    flex-flow: nowrap;
    overflow-x: auto;
    padding-right: 11px;

    .tag {
      padding: 6px 10px 7px 11px;
      margin-right: 0.75em;

      &:hover {
        text-decoration-line: none;
        transform: none;

        .tag-close-btn {
          font-weight: inherit;
        }
      }

      .tag-close-btn {
        font-size: $fontSizeSM;
        margin-left: 16px;
      }

      .tag-label {
        font-size: $fontSizeSM;
        padding-right: 0;
      }
    }
  }
}
