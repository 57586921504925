.mobile-menu-canvas-overlay {
  z-index: 200;
  opacity: 0;
  pointer-events: none;
  &.show {
    pointer-events: auto;
    opacity: 0.85;
  }
}

.mobile-menu-canvas-overlay,
.mobile-menu-canvas {
  -o-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.mobile-menu-canvas {
  font-weight: $fontWeightStrong;
  max-width: 100%;
  width: 320px;
  z-index: 210;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  right: 0;
  margin-right: -330px;

  &.full-screen {
    width: 100%;
    margin-right: 100%;

    .mobile-menu-canvas-content {
      max-width: 100%;
      background-color: $white;

      .filter-header {
        display: none;
      }
    }

    .filter-menu-show-results {
      background-color: $gray-200;
      border-top: 2px solid #c3c7cb;
      bottom: 0;

      button {
        width: 100%;
        color: white;
        background-color: var(
          --colorButtonPrimaryBg,
          $defaultColorButtonPrimaryBg
        );
        border-radius: 10px;
      }
    }
  }

  .mobile-menu-header {
    border-bottom: 2px solid #c3c7cb;

    .btn-close-mobile-menu {
      position: absolute;
      top: 9px;
      right: 0;

      &:hover,
      &[aria-expanded="false"] {
        transform: scale(1.1);
      }
    }
  }

  .mobile-menu-canvas-content {
    .main-nav,
    .mobile-menu-extra {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
      flex-direction: column !important;

      a + a::before,
      li + li::before {
        content: "";
        position: absolute;
        padding-top: 1px;
        width: calc(100% - 3rem);
        background-color: #c3c7cb !important;
        display: block;
        top: 0;
      }

      li + li::before {
        margin-left: 2rem;
      }

      .dropdown-menu {
        margin-left: 0 !important;
      }
    }

    .nav-item {
      margin: 0;
    }

    .nav-link,
    .main-nav li {
      position: relative;
      padding: 2.2rem 1.6rem;
      height: auto;

      a {
        color: inherit !important;
        &.active {
          color: var(--colorSecondary, $secondary-color) !important;
        }
      }
    }
  }
}

.filter-buttons {
  gap: 10px;

  .filter-menu-toggler {
    color: $bg-dark-color;
    background: $bg-light-color;
    border: 2px solid $bg-gray-color;
    border-radius: 10px;

    &:hover {
      background-color: #e6e8e9;
    }
  }
}
