.pagination {
  .page-item {
    + .page-item {
      @include media-breakpoint-down(sm) {
        margin-left: 6px;
      }

      & {
        margin-left: 12px;
      }
    }

    .page-link {
      font-weight: $fontWeightStrong;
      color: var(--bg-dark);
      min-width: 34.5px;
      border: 1px solid $bg-gray-color;
      border-radius: 7px;

      .icon {
        vertical-align: middle;
        margin-left: -4px;
        margin-right: -4px;
        margin-top: -2px;
      }
    }

    .disabled {
      min-width: 0;
      padding: 0.8rem 0.3rem;
      background-color: transparent !important;
      border: none !important;
    }

    &.disabled {
      margin-left: 0;

      .page-link {
        display: none;
      }
    }

    &.active {
      .page-link {
        background-color: var(--colorPrimary, $primary-color) !important;
        border: 1px solid var(--colorPrimary, $primary-color);
      }
    }
  }
}
