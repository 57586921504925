.flash-messages {
  position: fixed;
  height: 5rem;
  width: 660px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4.8rem;
  z-index: 9001;
  left: 0;
  right: 0;

  @include media-breakpoint-down(sm) {
    width: 95%;
  }
}
