$default-logo-url: url("https://themes.timeedit.net/timeedit-2024-logo-en.svg");

body {
  overflow-y: scroll;
  background-color: $bg-color !important;
}

.logo {
  background-image: var(
    --customerLogoEN,
    var(--customerLogoGlobal, $default-logo-url)
  );
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
  height: 50px;

  @include media-breakpoint-down(md) {
    height: 40px !important;
    margin-bottom: 1em;
    margin-top: 1em;
  }
}

.customer-body-lang-nl {
  .logo {
    background-image: var(
      --customerLogoNL,
      var(--customerLogoGlobal, $default-logo-url)
    );
  }
}

// Should move to ThemeWrapper
.form__checkbox {
  font-family: $fontFamily;
  font-size: $fontSizeBase;
  font-weight: $fontWeightNormal;
  width: 100%;
  padding: 0.5rem 0;

  input[type="checkbox"]:checked + .ant-checkbox-inner {
    background-color: var(--colorButtonPrimaryBg, $defaultColorButtonPrimaryBg);
    border-color: var(--colorButtonPrimaryBg, $defaultColorButtonPrimaryBg);
  }

  &:hover {
    input[type="checkbox"]:checked + .ant-checkbox-inner {
      background-color: var(
        --colorButtonPrimaryActiveBg,
        $defaultColorButtonPrimaryActiveBg
      ) !important;
      border-color: var(
        --colorButtonPrimaryActiveBg,
        $defaultColorButtonPrimaryActiveBg
      ) !important;
    }
  }
}

a {
  text-decoration: none;
  color: var(--colorPrimary, $primary-color);
}

.link-color,
.text-primary,
.nav-tabs .nav-link:not(.active) {
  cursor: pointer;
  color: var(--colorPrimary, $primary-color) !important;

  &:hover,
  &:focus {
    color: $link-hover-color !important;
  }
}

.nav-link {
  &:hover,
  &:focus {
    color: $link-hover-color !important;
  }
}

.nav-tabs .nav-link.active {
  border-bottom: 1px solid #f4f6f8;
}

.react-fetch-progress-bar {
  background-color: var(--colorPrimary, $primary-color) !important;
}

.form-control,
.form-select {
  border: 1px solid #c3c7cb;
  border-radius: 10px;

  &::placeholder {
    color: $gray-500;
  }
}

.btn-default:hover {
  color: inherit !important;
}

.button .button-icon {
  font-size: inherit !important;
}

.btn-primary {
  background-color: var(--colorButtonPrimaryBg, $defaultColorButtonPrimaryBg);
  border-color: var(--colorButtonPrimaryBg, $defaultColorButtonPrimaryBg);

  &:is(:hover, :active, :focus, :focus-within) {
    background-color: var(
      --colorButtonPrimaryActiveBg,
      $defaultColorButtonPrimaryActiveBg
    ) !important;
    border-color: var(
      --colorButtonPrimaryActiveBg,
      $defaultColorButtonPrimaryActiveBg
    ) !important;
  }

  &:disabled {
    background-color: $gray-500;
  }
}

.btn {
  &.btn-secondary {
    color: $gray-900;
    background-color: $white;
    border-color: $gray-400;

    &:hover,
    &:focus,
    &:active,
    &:active:hover {
      border-color: $gray-400 !important;
      background-color: $gray-200 !important;
    }
  }
}

.button {
  &.primary {
    > i {
      color: var(
        --colorButtonPrimaryBg,
        $defaultColorButtonPrimaryBg
      ) !important;

      &:is(:hover, :active, :focus, :focus-within) {
        color: var(
          --colorButtonPrimaryActiveBg,
          $defaultColorButtonPrimaryActiveBg
        ) !important;
      }
    }
  }

  .btn {
    @include media-breakpoint-up(md) {
      font-size: $fontSizeLG !important;
    }
  }
}

.gap--0_4rem {
  gap: 0.4rem;
}

.column-gap--2_4rem {
  column-gap: 2.4rem;
}

.row-gap--1_2rem {
  row-gap: 1.2rem;
}

.saved-badge > span {
  background-color: var(--colorSecondary, $secondary-color) !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: $white;
  background-color: var(--colorSecondary, $secondary-color);
}

.search-section {
  .form-group {
    padding-right: 10px;
    cursor: pointer;
  }

  .form-control {
    border-right: 0;
    border-radius: 10px 0 0 10px;
    height: 48px;
  }

  .search-button {
    border: none;
  }

  mark {
    background-color: transparent;
    color: inherit;
    font-weight: inherit;
    padding: 0;
  }

  .rbt-aux {
    display: none;
  }
}

.dropdown {
  .dropdown-menu {
    display: none;
    max-height: 350px !important;
    -webkit-box-shadow: 0 2px 4px #00000029;
    box-shadow: 0 2px 4px #00000029;

    &.show {
      display: block;
    }

    .dropdown-item {
      font-size: $fontSizeBase;
    }
  }
}

.nav-item {
  list-style: none;
}

.filters {
  max-width: 321px;

  .filter-header {
    padding-top: 1rem;
  }

  > div:not(.active):not(.no-separator) + div:not(.active):not(.no-separator) {
    border-top: 1px solid $separator-color;
  }

  legend {
    font-size: $fontSizeBase !important;
  }

  .form-check-label {
    padding-top: 0.3em;
    padding-bottom: 0.3em;
  }

  .form-label {
    user-select: none;
    margin-bottom: 0;
  }

  .clickable {
    .form-label {
      cursor: pointer;
    }
  }

  .filters-text {
    border-bottom: 2px solid $separator-color;
  }

  .select:nth-child(2) {
    border-top: none;
  }
}

.details__content__main,
.pagination {
  .dropdown-toggle {
    border: none;
    color: $bg-dark-color;
    background-color: transparent !important;
  }
}

.details__content__main .details__content__main__section {
  padding-top: 4px;
  padding-bottom: 4px;
}

.active .page-link {
  color: $white !important;
}

:not(.quickview) .details__content__container {
  padding: 0.6rem 0 0.8rem 0;

  .details__content__extra {
    min-width: 370px;
    max-width: 370px;

    .details__content__header {
      font-size: $fontSizeXL;
      color: var(--colorHeadingPrimaryText, $defaultColorHeadingPrimaryText);
      background-color: $bg-light-color;
      border-radius: 10px 10px 0 0;
      padding: 16px 22px 16px 22px;
    }

    .details__content {
      -webkit-box-shadow: 0 5px 13px #00000030;
      box-shadow: 0 5px 13px #00000030;
      border-radius: 10px;
      margin-bottom: 42px;
      padding: 0;

      .details__content__section {
        overflow: auto; // in case widgets are too big for placement
        padding: 16px 0 16px 0;
        margin: 0 22px;

        &:not(.details__content__section:last-of-type) {
          border-bottom: 1px solid $separator-color;
        }

        a + a {
          margin-top: 0.5em;
        }
      }
    }

    @include media-breakpoint-down(md) {
      min-width: 100%;
    }
  }
}

.card {
  border: none;
  border-radius: 15px;
  -webkit-box-shadow: 0 2px 4px #00000030;
  box-shadow: 0 2px 4px #00000030;

  .card-header {
    background-color: white;
  }

  .card-body {
    border-top: 1px solid $gray-300;
  }
}

.search-section-typeahead .form-control {
  border-width: 2px;
}

@include media-breakpoint-down(md) {
  .search-section {
    .search-section-typeahead {
      width: 100% !important;

      #searchbar {
        width: calc(100% + 54px) !important;
        margin-top: 0 !important;
        transform: translate3d(0px, 44px, 0px) !important;
        border-radius: 0 0 0.4rem 0.4rem !important;
        border: 2px solid #c3c7cb;
      }
    }

    .search-button {
      width: 60px !important;
    }

    &.focused {
      .form-control {
        border-radius: 10px 0 0 0 !important;
      }

      .search-button {
        border-radius: 0 10px 0 0 !important;
      }
    }
  }
}

.actions {
  float: right;
  white-space: nowrap;
}

.select.filters {
  margin-bottom: 0 !important;
}

.modal-title {
  font-size: $fontSizeXL;
}

.modal-header + .modal-footer {
  border-top: 0;
}

.modal-body {
  padding: 1.6rem;
}

.modal-footer {
  padding: 1.6rem;
}

dl.horizontal {
  column-gap: 20px;
  display: grid;
  grid-template-columns: max-content auto;
  margin-bottom: 0;

  dt {
    grid-column-start: 1;
  }

  dd {
    grid-column-start: 2;
  }
}

img,
video,
iframe,
embed,
object,
canvas,
picture,
figure {
  width: 100%;
  max-width: 100%;
  height: auto;
}
