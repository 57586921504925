#footer {
  align-items: center;
  padding-left: $app-frame-xl-padding-x;
  padding-right: $app-frame-xl-padding-x;
  background: $defaultColorFooterBg;

  a {
    color: $gray-700;

    &:hover,
    &:focus {
      color: var(--colorPrimary, #007644) !important;
      text-decoration: underline;
    }

    + a {
      margin-left: 2.4rem;
    }
  }

  @include media-breakpoint-down(xl) {
    padding-left: $app-frame-padding-x;
    padding-right: $app-frame-padding-x;
  }

  @include media-breakpoint-down(sm) {
    > div {
      flex-direction: column;
      align-items: flex-start;

      a {
        padding: 7px 0;

        + a {
          margin-left: 0;
        }
      }
    }
  }
}
